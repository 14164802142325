import React, { useState, useEffect } from "react";
import "../../src/Table.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../components/PaginationComponent";
import axiosInstance from "../services/axios";
import "../pages/Addsite.css";
import Inventory from "./Inventory";
import InventoryLog from "./InventoryLogs";


let PageSize = 10;

const OutwardLog = () => {
  const navigate = useNavigate();

  return (
    <>
      <InventoryLog
        type={'decrease'} />
    </>
  );
};

export default OutwardLog;
