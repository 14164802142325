import React, { useEffect, useState, useMemo } from "react";
import "../../src/Table.css";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../services/axios";
import Pagination from "../components/PaginationComponent";
import commonService from "../services/CommonServices";
import "../pages/Addsite.css";
import { FaTimes } from "react-icons/fa";
import img1 from "../assests/empty1.png";
import NoData from "../components/NoData";
import { ToastContainer, toast } from "react-toast";
import Loader from "../components/Loader";
let PageSize = 10;

const Users = () => {
  const navigate = useNavigate();

  const [sitesList, setSitesList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [siteData, setSiteData] = useState({
    name: "",
    email: "",
    role_id: null,
    password: null,
    password_confirmation: null,
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    console.log(e.target.name);
    const { name, value } = e.target;
    setSiteData({ ...siteData, [name]: value });
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (e.target.name == "email") {
      if (!emailPattern.test(e.target.value)) {
        setErrors({ email: "Please enter exact email id" });
      } else {
        setErrors({});
      }
    }
    if (
      e.target.name == "password" ||
      e.target.name == "password_confirmation"
    ) {
      const match = siteData?.password === siteData?.password_confirmation;
      if (match && siteData?.password_confirmation != null) {
        setErrors({});
      } else {
        setErrors({ password: "Password and confirm password must be same" });
        setErrors({
          password_confirmation: "Password and confirm password must be same",
        });
      }
    }
  };

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    fetchAllSites();
  }, [currentPage]);

  useEffect(() => {
    fetchAllSites();
    fetchRoles();
  }, []);

  useEffect(() => {
    console.log("selected site : ", siteData);
  }, siteData);

  const fetchRoles = async () => {
    setRolesList([]);
    try {
      const rolesResponse = await axiosInstance.get(`/roles/getAllRoles`);
      if (rolesResponse.data["success"] === true) {
        setRolesList(rolesResponse.data.data.roles);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const fetchAllSites = async () => {
    setSitesList([]);
    setLoader(true);
    try {
      const response = await axiosInstance.get(
        `/users/getAllUsers?limit=${PageSize}&pageNo=${currentPage}`
      );
      if (response.data["success"] == true) {
        console.log(response.data.data.users); // handle the response from the server
        setSitesList(response.data.data.users);
        setTotalCount(response.data.data.count);
        setLoader(false);
      }
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    setSiteData({ ...siteData, role_id: rolesList[1]?.id });
  }, [rolesList]);

  useEffect(() => {
    console.log("Site data", siteData);
  });

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const deleteSelectedSite = async () => {
    try {
      const response = await axiosInstance.delete(
        `/users/deleteUser/${selectedSite.id}`
      );
      if (response.data["success"] == true) {
        console.log(response); // handle the response from the server
        fetchAllSites();
        setCurrentPage(0);
        setSelectedSite({});
      }
    } catch (error) {
      console.log(error); // handle the error
      toast.error(error?.response?.data?.message);
      setSelectedSite({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let apiUrl = null;
    let isError = null;
    if (Object.keys(siteData).length > 0) {
      Object.keys(siteData).map((item, index) => {
        if (item != "created_at" && item != "updated_at") {
          if (siteData?.[item] == null || siteData?.[item] == "") {
            const { name, value } = {
              name: item,
              value: `${item} is required!`,
            };
            setErrors({ ...errors, [name]: value });
          }
        }
      });
    } else {
      setErrors({
        name: "Name is required",
        uom: "Unit of Measurement is required",
      });
      return;
    }
    if (Object.keys(errors)?.length > 0) {
      Object.keys(errors).map((item, index) => {
        if (errors?.[item] != null || errors?.[item] != "") {
          const { name, value } = {
            name: item,
            value: `${item} is required!`,
          };
          setErrors({ ...errors, [name]: value });
          return;
        }
      });
    }
    setLoader(true);
    if (Object.keys(selectedSite).length > 0) {
      apiUrl = `/users/updateUser/${selectedSite?.id}`;
    } else {
      apiUrl = "/users/addUser";
    }
    try {
      const response = await axiosInstance.post(apiUrl, siteData);
      if (response.data["success"] == true) {
        fetchAllSites();
        setCurrentPage(0);
        setSelectedSite({});
        setSiteData({});
        setOpenForm(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 412) {
        setErrors(error?.response?.data?.data);
      }
      setLoader(false);
    }
  };

  return (
    <>
      <h1 className="heading"></h1>
      <div
        className="relative overflow-x-auto sm:rounded-lg"
        style={{ width: "100%", padding: "20px", overflowY: "scroll" }}
      >
        {loader ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className="bg-white tableDiv">
              <div className="">
                {/* <Link
              to="/add-category"
              className="text-white bg-blue-700  font-medium  text-sm px-6 py-4 text-center b1"
            >
              Add Sites
            </Link> */}
                <button
                  onClick={() => {
                    setOpenForm(true);
                    setSiteData({ role_id: rolesList[1]?.id });
                  }}
                  className="btn-sm float-right"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "5px",
                    alignItems: "center",
                    width: "100px",
                    background:"#814f68",
                  }}
                >
                  <span style={{ fontSize: "24px" }}>+</span> Add
                  {/* Add New Users */}
                </button>
              </div>
              <div className="ml-4">
                <h1 className=" text-2xl py-6">Users</h1>
              </div>
              {sitesList.length > 0 ? (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  bg-white">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        {/* <input type="checkbox"></input> */}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Email Id
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Role
                      </th>
                      <th scope="col" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sitesList.length > 0 ? (
                      Object.values(sitesList)?.map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        >
                          <td scope="col" className="px-6 py-3">
                            {/* <input type="checkbox"></input> */}
                            {index + 1}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.name}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.email}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.role_details?.name}
                          </td>
                          <td className="px-6 py-4">
                            <div className="row align-item-center justify-space-space">
                              <a
                                href="#"
                                onClick={() => {
                                  setSiteData(item);
                                  setOpenForm(true);
                                  setSelectedSite(item);
                                }}
                                className="font-medium text-[#814f68]-600 dark:text-blue-500 hover:underline ml-4"
                              >
                                Edit
                              </a>

                              <a
                                href="#"
                                className="font-medium text-red-600 dark:text-blue-500 hover:underline ml-4"
                                onClick={() => {
                                  setSubmissionModal(true);
                                  setSelectedSite(item);
                                }}
                              >
                                Delete
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoData />
                    )}
                  </tbody>
                </table>
              ) : (
                <NoData></NoData>
              )}
            </div>
            {sitesList.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  className="mb-2"
                  totalPosts={totalCount}
                  postsPerPage={PageSize}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        {submissionModal && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-20 z-50"
                style={{ width: "800px", maxWidth: "100%" }}
              >
                <div className="p-4 text-center sm:p-0 res">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5 res">
                      <div className="flex justify-between items-center res">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          Please Confirm
                        </h2>
                        {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                      </div>
                      <div>
                        <div className="flex py-10 justify-between items-center res">
                          <p className="text-[16px] font-bold helvetica not-italic mb-4 text-red-600">
                            Are you sure you want to delete?
                          </p>
                          <div className="flex gap-10 pr-2">
                            <button
                              className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                              onClick={async () => {
                                setSubmissionModal(false);
                                // nextStep();
                                await deleteSelectedSite(true);
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => {
                                setSubmissionModal(false);
                              }}
                              className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {openForm && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-15 z-50"
                style={{ width: "600px", maxWidth: "90%", overflowY: "scroll" }}
              >
                <div className="p-4 text-center sm:p-0">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5">
                      <div className="flex justify-between items-center">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          Add New User
                        </h2>
                        {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                        <button
                          className=""
                          onClick={() => {
                            setOpenForm(false);
                            setSiteData({});
                          }}
                          type="reset"
                        >
                          <FaTimes />
                        </button>
                      </div>
                      <form className="addsite" onSubmit={handleSubmit}>
                        <h6 className="small">
                          User Name <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <input
                          className="textbox"
                          type="text"
                          name="name"
                          placeholder="Enter User Name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={siteData.name}
                        />
                        {errors.name && (
                          <p className="error-text">{errors.name}</p>
                        )}
                        <h6 className="small">
                          Email Id <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <input
                          className="textbox"
                          type="email"
                          name="email"
                          placeholder="Enter Users Email"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={siteData.email}
                        />
                        {errors.email && (
                          <p className="error-text">{errors.email}</p>
                        )}
                        <h6 className="small">
                          Select Role <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <select
                          className="textbox"
                          placeholder="Please Select Role"
                          name="role_id"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={siteData?.role_id}
                        >
                          {errors.role_id && (
                            <p className="error-text">{errors.role_id}</p>
                          )}
                          {rolesList ? (
                            Object.values(rolesList)?.map(
                              (item, index) =>
                                item?.name != "Admin" && (
                                  <option key={index} value={item?.id}>
                                    {item?.name}
                                  </option>
                                )
                            )
                          ) : (
                            <span>N/A</span>
                          )}
                        </select>
                        {Object.keys(selectedSite).length == 0 ? (
                          <>
                            <h6 className="small">
                              Enter Password{" "}
                              <span style={{ color: "red" }}>*</span>{" "}
                            </h6>
                            <input
                              className="textbox"
                              type="password"
                              name="password"
                              placeholder="Enter Users Password"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={siteData.password}
                            />
                            {errors.password && (
                              <p className="error-text">{errors.password}</p>
                            )}
                            <h6 className="small">
                              Confirm Password{" "}
                              <span style={{ color: "red" }}>*</span>{" "}
                            </h6>
                            <input
                              className="textbox"
                              type="password"
                              name="password_confirmation"
                              placeholder="Confirm Password"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={siteData.password_confirmation}
                            />
                            {errors.password_confirmation && (
                              <p className="error-text">
                                {errors.password_confirmation}
                              </p>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="flex w-100 gap-5 justify-space-space px-10 mt-4">
                          <button
                            className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#383838]   text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                            type="submit"
                          >
                            {Object.keys(selectedSite).length > 0 ? (
                              <span>Update</span>
                            ) : (
                              <span>Submit</span>
                            )}
                          </button>
                          <button
                            onClick={() => {
                              setOpenForm(false);
                              setSiteData({});
                              setSelectedSite({});
                            }}
                            type="reset"
                            className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                          >
                            Close
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <ToastContainer position="top-center" autoClose={2000} />
      </div>
    </>
  );
};

export default Users;
