import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CheckPermissionComponent = (props) => {
    // return props.children;
    const userPermissions = JSON.parse(localStorage.getItem('userDetails'));
    const managerPermissionList = [];
    const adminSupervisorPermissionList = [
        'SHOW_USER_MANAGEMENT',
        'ADD_UNIT',
        'SHOW_UNIT_ACTION',
        'ADD_PRODUCT',
        'SHOW_PRODUCT_ACTION',
        'ADD_CATEGORY',
        'SHOW_CATEGORY_ACTION',
        'ADD_SITE',
        'SHOW_SITE_ACTION',
        'ADD_FOLDER',
        'SHOW_FOLDER_ACTION',
        'ADD_INVENTORY',
        'SHOW_INVENTORY_ACTION',
        'ADD_FILE',
        'SHOW_FILE_ACTION'
    ];
    if(userPermissions?.role_details?.name == 'Admin' || userPermissions?.role_details?.name == 'Supervisor'){
        if(adminSupervisorPermissionList.includes(props.permission)){
            return props.children;
        }
    }
    if(userPermissions?.role_details?.name == "Manager"){
        if(managerPermissionList.includes(props.permission)){
            return props.children;
        }
    }
    return false;
};

CheckPermissionComponent.propTypes = {
    permission: PropTypes.string.isRequired,
    userPermissions: PropTypes.array.isRequired
};


// const mapStateToProps = state => ({
//     userPermissions: state.user.permission //<--- here you will get permissions for your user from Redux store
// });

export const CheckPermission = (CheckPermissionComponent);