import React, { useEffect } from "react";
import "./Dashboard1Styles.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaClipboardList, FaTimes, FaUnity } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { BiHome } from "react-icons/bi";
import { BsBox } from "react-icons/bs";
import { MdPresentToAll } from "react-icons/md";
import { SiChainlink } from "react-icons/si";
import { RiFileList2Line, RiProductHuntLine } from "react-icons/ri";
import { VscFolder } from "react-icons/vsc";
import { GrLocationPin, GrStorage, GrUserManager } from "react-icons/gr";
import { Outlet } from "react-router-dom";
import { MdAdUnits } from "react-icons/md";
import { CheckPermission } from "../components/CheckPermissions";

const Dashboard1 = ({setLoader}) => {
  const navigate = useNavigate();

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const logout = async () => {
    setLoader(true);
    setTimeout(()=>{
      localStorage.clear();
      navigate("/login");
      setLoader(false)
    }, 1500)
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1040);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    // Function to update isMobile state based on window width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1040);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateIsMobile);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  console.log(click);

  return (
    <div className="dcontainer">
      <div className="joint">
        <BiHome size={23} style={{color:"#814f68"}} />
        <h2 style={{ paddingLeft: "10px",color:"#814f68"}}>{!isMobile && "Dashboard"}</h2>
      </div>
        <ul
          className={click ? "nav-menu active" : "nav-menu"}
          style={{ zIndex: 10 }}
        >
          <div className="align">
            <div className="place">
              <h3 className="light">Products</h3>

              {/* <div className="con">
                <SiChainlink />
                <Link
                  to="/category-list"
                  style={{ paddingLeft: "0.4rem" }}
                  onClick={handleClick}
                >
                  Category
                </Link>
              </div> */}
              <div className="con">
                <RiProductHuntLine />
                <Link
                  to="/product-list"
                  style={{ paddingLeft: "0.5rem" }}
                  onClick={handleClick}
                >
                  Products
                </Link>
              </div>
              <div className="con">
                <FaUnity />
                <Link
                  to="/units-list"
                  style={{ paddingLeft: "0.5rem" }}
                  onClick={handleClick}
                >
                  Units
                </Link>
              </div>
            </div>

            <div className="place">
              <h3 className="light">Sites</h3>
              <div className="con">
                <GrLocationPin />
                <Link
                  to="/sites-list"
                  style={{ paddingLeft: "0.5rem" }}
                  onClick={handleClick}
                >
                  Sites
                </Link>
              </div>
              <div className="con">
                <VscFolder />
                <Link
                  to="/folders"
                  style={{ paddingLeft: "0.5rem" }}
                  onClick={handleClick}
                >
                  Folders
                </Link>
              </div>
              <div className="con">
                <RiFileList2Line />
                <Link
                  to="/site-inventory"
                  style={{ paddingLeft: "0.5rem" }}
                  onClick={handleClick}
                >
                  Site Inventory
                </Link>
              </div>
              <div className="place">
                <h3 className="light">Inventory</h3>
                <div className="con">
                  <GrStorage />
                  <Link
                    to="/inventory"
                    style={{ paddingLeft: "0.5rem" }}
                    onClick={handleClick}
                  >
                    Inventory
                  </Link>
                </div>
                <div className="con">
                  <BsBox />
                  <Link
                    to="/inward-log"
                    style={{ paddingLeft: "0.5rem" }}
                    onClick={handleClick}
                  >
                    Inwords Logs
                  </Link>
                </div>
                <div className="con">
                  <MdPresentToAll />
                  <Link
                    to="/outward-log"
                    style={{
                      paddingLeft: "0.5rem",
                      paddingRight: "0.1rem",
                      width: "140px",
                    }}
                    onClick={handleClick}
                  >
                    Outwards Logs
                  </Link>
                </div>
              </div>
              <div className="place">
                <h3 className="light">Users</h3>
                <CheckPermission permission="SHOW_USER_MANAGEMENT">
                  <div className="con">
                    <GrUserManager />
                    <Link
                      to="/users-list"
                      style={{ paddingLeft: "0.3rem" }}
                      onClick={handleClick}
                    >
                      User Management
                    </Link>
                  </div>
                </CheckPermission>

                <div className="con" onClick={() => setLoader(true)}>
                  <FiLogOut />
                  <Link onClick={logout} style={{ paddingLeft: "0.5rem" }}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ul>
      <div
        className="hamburger"
        onClick={handleClick}
        style={{
          display: "flex",
          marginLeft: "1rem",
          cursor: "pointer",
          zIndex: click === true ? 11 : 0,
          position: click === true ? "absolute" : "relative",
          right: click === true ? "15px" : "0px",
          top: click === true ? "10px" : "0px",
          transition: click === true ? "all 300ms" : "",
        }}
      >
        {/* {click ? (condition1) : (condition2)} */}
        {click ? (
          <FaTimes size={20} style={{ color: "black" }} />
        ) : (
          <FaBars size={20} style={{ color: "black" }} />
        )}
      </div>
    </div>
  );
};

export default Dashboard1;
