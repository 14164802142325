import React, { useEffect, useState, useMemo } from "react";
import "../../src/Table.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../services/axios";
import Pagination from "../components/PaginationComponent";
import { FaTimes } from "react-icons/fa";
import img1 from "../assests/empty1.png";

let PageSize = 10;

const SiteInventoryLog = () => {
  const navigate = useNavigate();

  const [sitesList, setSitesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [selectedSite] = useState({});

  useEffect(() => {
    fetchAllSites();
  }, [currentPage]);

  useEffect(() => {
    fetchAllSites();
  }, []);

  const fetchAllSites = async () => {
    setSitesList([]);
    try {
      const response = await axiosInstance.get(
        `/site_inventorylog/getAllSiteInventoryLog?limit=${PageSize}&pageNo=${currentPage}`
      );
      if (response.data["success"] === true) {
        console.log(response.data.data.inventory_log); // handle the response from the server
        setSitesList(response.data.data.site_inventory_log);
        setTotalCount(response.data.data.count);
      }
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    console.log("Site Data : ", JSON.stringify(sitesList));
  }, [sitesList]);

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const deleteSelectedSite = async () => {
    console.log("Selected Site : ", selectedSite);
    try {
      const response = await axiosInstance.delete(
        `/sites/deleteSite/${selectedSite.id}`
      );
      if (response.data["success"] === true) {
        console.log(response); // handle the response from the server
        fetchAllSites();
      }
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.data?.message === "Token is Expired") {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  return (
    <>
      <h1 className="heading"></h1>
      <div className="relative overflow-x-auto sm:rounded-lg ml-44 top1 t1">
        <div className="bg-white mb-12 ml-8 p-3">
          <h1 className="text-2xl bg-white-600 p-3 top2 t1">Site Inventory Log</h1>
        </div>
        <div className="bg-white ml-8 pb-4 ">
          {/* <div className="">
            <Link
              to="/AddSite"
              className="text-white bg-blue-700  font-medium  text-sm px-6 py-4 text-center b1"
            >
              Add Site
            </Link>
          </div> */}
          <h1 className="mb-8 ml-4 text-2xl top3 pt-6">Site Inventory Log</h1>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-16 bg-white">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  <input type="checkbox"></input>
                </th>
                <th scope="col" className="px-6 py-3">
                  Item name
                </th>
                <th scope="col" className="px-6 py-3">
                  Site
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
              </tr>
            </thead>
            <tbody>
              {sitesList.length > 0 ? (
                Object.values(sitesList)?.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                  >
                    <td scope="col" className="px-6 py-3">
                      <input type="checkbox"></input>
                    </td>
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.stock_details?.name} <br></br>{" "}
                      {item.stock_details?.item_code}
                    </td>
                    <td className="px-6 py-4">
                      {item.site_details?.name ? item.site_details?.name : "-"}
                    </td>
                    <td className="px-6 py-4">{item.qty}</td>
                    <td className="px-6 py-4">{item.type.toUpperCase()}</td>
                    {/* <td className="px-6 py-4">
                      <a
                        href="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline ml-4"
                      >
                        Edit
                      </a>

                      <a
                        href="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline ml-4"
                        onClick={() => {
                          setSubmissionModal(true);
                          setSelectedSite(item)
                        }}
                      >
                        Delete 
                      </a>
                    </td> */}
                  </tr>
                ))
              ) : (
                <img src={img1} className="ml-80"></img>
              )}
            </tbody>
          </table>
          <Pagination
            className="mb-2"
            totalPosts={totalCount}
            postsPerPage={PageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
        {submissionModal && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-15 z-50"
                style={{ width: "800px", maxWidth: "100%" }}
              >
                <div className="p-4 text-center sm:p-0 res">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5 res">
                      <div className="flex justify-between items-center res">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          Please Confirm
                        </h2>
                        {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                      </div>
                      <div>
                        <div className="flex py-10 justify-between items-center res">
                          <p className="text-[16px] font-bold helvetica not-italic mb-4 text-red-700">
                            Are you sure you want to delete?
                          </p>
                          <div className="flex gap-10 pr-10">
                            <button
                              className="bg-[#00617F] text-[16px] font-bold helvetica border hover:border-[#00617F] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                              onClick={async () => {
                                setSubmissionModal(false);
                                // nextStep();
                                await deleteSelectedSite(true);
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => {
                                setSubmissionModal(false);
                              }}
                              className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#00617F] hover:text-[#fff] text-[#00617F] px-10 py-2 rounded-[8px] border border-[#00617F]"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SiteInventoryLog;
