import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { loginData } from "../../content";
// import { connect } from "react-redux";
import "./LoginStyles.css";
import img1 from "../assests/PhotoRoom.png";
import { Link } from "react-router-dom";
import { login } from "../store/action/addData";
import { Provider, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toast";
import axiosInstance from "../services/axios";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { CountertopsOutlined } from "@mui/icons-material";

const Login = ({ res }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  // const dispatch = useDispatch();
  const [data, setData] = useState({
    email: null,
    password: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleLogin = async () => {
    setLoader(true);
    if (
      data?.email == null ||
      data?.email == "" ||
      data?.password == null ||
      data?.password == ""
    ) {
      toast.error("Email and password are required!");
      setTimeout(() => {
        setLoader(false);
      }, 1500);
      return;
    }
    try {
      const response = await axiosInstance.post("/users/login", data);
      if (response.data["success"] == true) {
        let UserDetails = response.data.data;
        localStorage.setItem("token", JSON.stringify(UserDetails.token));
        localStorage.setItem(
          "userDetails",
          JSON.stringify(UserDetails.userData)
        );
        localStorage.setItem("loginData", JSON.stringify(UserDetails));
        setToken(response.data.data);
       
      }
      setTimeout(()=>{
        setLoader(false);
      }, 1500)
    } catch (error) {
      console.log(error); // handle the error
      setError(error.response.data.message)
      setLoader(false);
    }
  };

  useEffect(()=>{
    console.log('Error : ', error)
  },[error])

  const setToken = (UserDetails) => {
    // setTimeout(()=>{
    //   navigate("/units-list");
    // }, 1000)
    window.location.reload();
  };

 
  

  function getToken() {}

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    console.log("loginData@@@@@00", loginData);
    if (loginData && loginData.token != null) {
      // localStorage.setItem("token", loginData?.data?.token);
      // const jsonString = JSON.stringify(loginData?.data?.userDetails);
      // localStorage.setItem("userDetails", jsonString);
      // localStorage.setItem("userRole", loginData?.data?.userDetails?.role);
      // if (loginData?.data?.userDetails?.role == "SuperAdmin") {
      //   navigate("/superadmin");
      // } else {
      //   navigate("/budget-summary");
      // }
      navigate("/sites-list");
    }
  },
   []);

  

  return (
    <>
      {loader ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className="container1">
          <div className="right">
            <img className="into-img" src={img1} />
          </div>
          <div className="leftcontainer">
            <div className="left">
              <p className="largetext">SIGN IN</p>
              <h2 className="smalltext">Sign in to continue our application</h2>
              <div className="form ">
                <div className="res ">
                  <input
                    type="email"
                    name="email" id="myInput" 
                    placeholder="  email@gmail.com"
                    className="input"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  ></input>
                </div>
                <div>
                  <input
                    type="password"
                    name="password" id="myInput" 
                    placeholder="  Password"
                    className="input"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  ></input>
                </div>
                {
                  error ? (
                    <>
                    <span style={{ color:'red' }} >{error}</span>
                    </>
                  ):(
                    <></>
                  )
                }
                <button className="btn1" onClick={handleLogin}>
                  Sign In
                </button>
              </div>
            </div>
          </div>
          
        </div>
      )}
      <div style={{ marginTop:'40px' }} >
        <Footer />
      </div>

    
    </>
  );
};

export default Login;
