import React, { useState, useEffect } from "react";
import "../../src/Table.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../components/PaginationComponent";
import axiosInstance from "../services/axios";
import "../pages/Addsite.css";
import { ToastContainer, toast } from "react-toast";
import { FaTimes, FaRedoAlt, FaAngleDown } from "react-icons/fa";
import { CheckPermission } from "../components/CheckPermissions";
import img1 from "../assests/empty1.png";
import NoData from "../components/NoData";
import Select from "react-select";

import Loader from "../components/Loader";
import * as XLSX from "xlsx";

let PageSize = 10;

const ProductList = () => {
  const navigate = useNavigate();

  const [sitesList, setSitesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [addConfirmationModal, setAddConfirmationModal] = useState(false);
  const [siteData, setSiteData] = useState({
    name: "",
    item_code: "",
    stock_unit_id: null,
    stock_category_id: null,
    price_per_unit: null,
    weight_per_unit: null,
  });
  const [unitOptions, setUnitOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSiteData({ ...siteData, [name]: value });
  };
  const [file, setFile] = useState();
  const [showUploadFileForm, setShowUploadFileForm] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    id: null,
    keyword: null
  });
  const [selectFilter, setSelectedFilter] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [keywordData, setKeywordData] = useState(null);


  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    fetchAllSites();
  }, [currentPage]);

  useEffect(() => {
    fetchAllSites();
    fetchUnitsAndCategories();
    fetchAllProducts();
  }, []);

  useEffect(() => {
    console.log("selected site : ", siteData);
  }, siteData);

  useEffect(() => {
    setCurrentPage(0);
    fetchAllSites();
  }, [filterOptions]);

  const fetchUnitsAndCategories = async () => {
    setUnitOptions([]);
    setCategoryOptions([]);
    try {
      const unitResponse = await axiosInstance.get(`/units/getAllUnits`);
      const categoryResponse = await axiosInstance.get(
        `/categories/getAllCategories`
      );
      if (unitResponse.data["success"] === true) {
        setUnitOptions(unitResponse.data.data.units);
      }
      if (categoryResponse.data["success"] === true) {
        setCategoryOptions(categoryResponse.data.data.categories);
      }
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const fetchAllSites = async () => {
    setSitesList([]);
    setLoader(true);
    try {
      let url = `/products/getAllProducts?limit=${PageSize}&pageNo=${currentPage}`;
      if (
        filterOptions?.["id"] != null &&
        filterOptions?.["id"] != ""
      ) {
        url = `${url}&id=${filterOptions["id"]}`;
      }
      if (
        filterOptions?.["keyword"] != null &&
        filterOptions?.["keyword"] != ""
      ) {
        url = `${url}&keyword=${filterOptions["keyword"]}`;
      }
      const response = await axiosInstance.get(url);
      // const response = await axiosInstance.get(
      //   `/products/getAllProducts?limit=${PageSize}&pageNo=${currentPage}`
      // );
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (response.data["success"] === true) {
        setSitesList(response.data.data.products);
        setTotalCount(response.data.data.count);
      }
    } catch (error) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const fetchAllProducts = async () => {
    setProductOptions([]);
    try {
      const response = await axiosInstance.get(`/products/getAllProducts`);
      if (response.data["success"] === true) {
        const options = [];
        response.data.data.products.map((item, index) => {
          options.push({ value: item?.id, label: item?.name });
        });
        setProductOptions(options);
      }
    } catch (error) {
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    setSiteData({ ...siteData, stock_unit_id: unitOptions[0]?.id });
  }, [unitOptions]);

  useEffect(() => {
    // console.log("Site data", siteData);
  });

  const deleteSelectedSite = async () => {
    setLoader(true);
    try {
      const response = await axiosInstance.delete(
        `/products/deleteProduct/${selectedSite.id}`
      );
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (response.data["success"] === true) {
        console.log(response); // handle the response from the server
        fetchAllSites();
      }
    } catch (error) {
      console.log(error); // handle the error
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (error?.response?.status == 412) {
        toast.error('Product is added in inventory you can`t delete it');
      }
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let apiUrl = null;
    let isError = null;
    setLoader(true);
    setErrors({});
    console.log("site Data : ", siteData);
    if (Object.keys(siteData).length > 0) {
      Object.keys(siteData).map((item, index) => {
        if (
          item != "created_at" &&
          item != "updated_at" &&
          item != "stock_category_id" &&
          item != "stock_unit_id"
        ) {
          console.log(item, ":", siteData[item]);
          if (siteData?.[item] == null || siteData?.[item] == "") {
            const { name, value } = {
              name: item,
              value: `${item} is required!`,
            };
            setErrors({ ...errors, [name]: value });
          }
        }
      });
    } else {
      setErrors({
        name: "Name is required",
        item_code: "Item code is required",
        price_per_unit: "Price per unit is required",
        weight_per_unit: "Weight per unit is required",
      });
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      return;
    }
    if (Object.keys(errors)?.length > 0) {
      Object.keys(errors).map((item, index) => {
        if (errors?.[item] != null || errors?.[item] != "") {
          const { name, value } = {
            name: item,
            value: `${item} is required!`,
          };
          setErrors({ ...errors, [name]: value });
          setTimeout(() => {
            setLoader(false);
          }, 1000);
          return;
        }
      });
    }
    let payload = {
      name: siteData["name"],
      price_per_unit: siteData["price_per_unit"],
      item_code: siteData["item_code"],
      weight_per_unit: siteData["weight_per_unit"],
    };
    if (siteData["stock_category_id"]) {
      payload["stock_category_id"] = siteData["stock_category_id"];
    }
    if (siteData["stock_unit_id"]) {
      payload["stock_unit_id"] = siteData["stock_unit_id"];
    }
    if (Object.keys(selectedSite).length > 0) {
      apiUrl = `/products/updateProduct/${selectedSite?.id}`;
    } else {
      apiUrl = "/products/addProduct";
    }
    try {
      const response = await axiosInstance.post(apiUrl, payload);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (response.data["success"] == true) {
        fetchAllSites();
        setSelectedSite({});
        setSiteData({});
        setOpenForm(false);
      }
    } catch (error) {
      console.log(error); // handle the error
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (error?.response?.status == 412) {
        setErrors(error?.response?.data?.data);
      }
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const uploadExcel = async (e) => {
    e.preventDefault();
    let apiUrl = null;
    setLoader(true);
    setErrors({});
    if (excelData.length <= 0) {
      setErrors({
        file: "Selected File is invalid",
      });
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      return;
    }
    let payload = {
      products: excelData,
    };
    apiUrl = "/products/addBulkProduct";

    try {
      const response = await axiosInstance.post(apiUrl, payload);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (response.data["success"] == true) {
        fetchAllSites();
        setShowUploadFileForm(false);
      }
    } catch (error) {
      console.log(error); // handle the error
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (error?.response?.status == 412) {
        setErrors(error?.response?.data?.data);
      }
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const fileSelectedHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet);

        setExcelData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const customstyles = {
    // control represent the select component
    control: (provided) => ({
      ...provided,
      width: "95%",
      height: "50px",
      border: "1px solid",
      borderRadius: "5px",
    }),
  };


  const handleKeywordChange = (e) => {
    const { name, value } = e.target;
    if(value.length > 3){
      setFilterOptions({ ...filterOptions, [name]: value });
    }
  };

  const handleFilterChange = (e, type) => {
    if (type === "product") {
      setSelectedFilter(e);
      setFilterOptions({ id: e?.value });
    }
  };

  useEffect(() => {
    console.log(excelData, "Excel Data ");
  }, [excelData]);

  return (
    <>
      <h1 className="heading"></h1>
      <div
        className="relative overflow-x-auto sm:rounded-lg"
        style={{ width: "100%", padding: "20px" }}
      >
        {loader ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className="bg-white tableDiv">
              <div className="">
                <CheckPermission permission="ADD_PRODUCT">
                  <button
                    onClick={() => {
                      setAddConfirmationModal(true);
                      // setOpenForm(true);
                    }}
                    className="btn-sm float-right"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      alignItems: "center",
                      width: "100px",
                      background:"#814f68",
                    }}
                  >
                    <span style={{ fontSize: "24px" }}>+</span> Add
                    {/* Add New Product */}
                  </button>
                </CheckPermission>
              </div>
              <div>
                <div className="ml-4">
                  <h1 className="text-2xl py-6 ">Products</h1>
                </div>
                <div
                className="flex row align-item-center bg-gray-50 px-5"
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  height: "30px",
                  borderBottom: "5px solid #fff",
                }}
              >
                <button
                  className=""
                  onClick={() => {
                    setFilterOptions(null);
                    setSelectedFilter(null);
                  }}
                  type="reset"
                >
                  <FaRedoAlt />
                </button>
                <button
                  className=""
                  onClick={() => {
                    setShowFilterOptions(!showFilterOptions);
                  }}
                  type="reset"
                >
                  <FaAngleDown />
                </button>
              </div>
              {showFilterOptions ? (
                <div
                  className="flex row px-5 align-item-center"
                  style={{ height: "80px", marginBottom: "20px" }}
                >
                  <div className="colFour">
                    <h6 className="small">Select Product</h6>
                    <Select
                      defaultValue={selectFilter}
                      value={selectFilter}
                      onChange={(e) => {
                        handleFilterChange(e, "product");
                      }}
                      options={productOptions}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "blue",
                          primary: "black",
                        },
                      })}
                      styles={customstyles}
                    />
                  </div>
                  {/* <div className="colFour">
                    <h6 className="small">Enter Keyword</h6>
                    <input
                        className="textbox"
                        type="text"
                        name="keyword"
                        placeholder="Enter Any Keyword"
                        onChange={(e) => {
                          handleKeywordChange(e);
                        }}
                        value={filterOptions.keyword}
                      />
                  </div> */}
                </div>
              ) : (
                <></>
              )}
                {sitesList.length > 0 ? (
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  bg-white">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          {/* <input type="checkbox"></input> */}
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Product name
                        </th>
                        {/* <th scope="col" className="px-6 py-3">
                    Category
                  </th> */}
                        <th scope="col" className="px-6 py-3">
                          Price / Unit
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Weight / Unit
                        </th>
                        <CheckPermission permission="SHOW_PRODUCT_ACTION">
                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </CheckPermission>
                      </tr>
                    </thead>
                    <tbody>
                      {sitesList.length > 0 ? (
                        Object.values(sitesList)?.map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                          >
                            <td scope="col" className="px-6 py-3">
                              {/* <input type="checkbox"></input> */}
                              {(index + 1)+(PageSize*currentPage)}
                            </td>
                            <td
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.name} <br></br>
                              {item.item_code}
                            </td>
                            {/* <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.category_details?.name}
                      </td> */}
                            <td
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.price_per_unit
                                ? item.price_per_unit
                                : "N/A"}{" "}
                              {item.price_per_unit ? "/" : ""}{" "}
                              {item?.price_per_unit
                                ? item?.unit_details
                                  ? item?.unit_details?.name
                                  : item?.unit
                                : ""}
                            </td>
                            <td
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item.weight_per_unit
                                ? item.weight_per_unit
                                : "N/A"}{" "}
                              {item.weight_per_unit ? "/" : ""}{" "}
                              {item?.weight_per_unit
                                ? item?.unit_details
                                  ? item?.unit_details?.name
                                  : item?.unit
                                : ""}
                            </td>
                            <CheckPermission permission="SHOW_PRODUCT_ACTION">
                              <td className="px-6 py-4">
                                <div className="row align-item-center justify-space-space">
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setSiteData(item);
                                      setOpenForm(true);
                                      setSelectedSite(item);
                                    }}
                                    className="font-medium text-[#814f68] -600 dark:text-blue-500 hover:underline ml-4"
                                  >
                                    Edit
                                  </a>

                                  <a
                                    href="#"
                                    className="font-medium text-red-600 dark:text-blue-500 hover:underline ml-4"
                                    onClick={() => {
                                      setSubmissionModal(true);
                                      setSelectedSite(item);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </td>
                            </CheckPermission>
                          </tr>
                        ))
                      ) : (
                        <NoData />
                      )}
                    </tbody>
                  </table>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
            {sitesList.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  className="mb-2"
                  totalPosts={totalCount}
                  postsPerPage={PageSize}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
                <ToastContainer position="top-center" autoClose={2000} />
      </div>

      {submissionModal && (
        <>
          <div className="fixed inset-0 flex items-top  justify-center z-50">
            <div
              className="absolute inset-0 backdrop-filter backdrop-blur-sm"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            ></div>
            <div
              className="relative top-20 z-50"
              style={{ width: "800px", maxWidth: "100%" }}
            >
              <div className="p-4 text-center sm:p-0 res">
                <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                  <div className="px-5 py-5 res">
                    <div className="flex justify-between items-center res">
                      <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                        Please Confirm
                      </h2>
                      {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                    </div>
                    <div>
                      <div className="flex py-10 justify-between items-center res">
                        <p className="text-[16px] font-bold helvetica not-italic mb-4 text-red-600">
                          Are you sure you want to delete?
                        </p>
                        <div className="flex gap-10 pr-2">
                          <button
                            className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                            onClick={async () => {
                              setSubmissionModal(false);
                              // nextStep();
                              await deleteSelectedSite(true);
                            }}
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => {
                              setSubmissionModal(false);
                            }}
                            className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {openForm && (
        <>
          <div className="fixed inset-0 flex items-top  justify-center z-50">
            <div
              className="absolute inset-0 backdrop-filter backdrop-blur-sm"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            ></div>
            <div
              className="relative top-20 z-50"
              style={{
                width: "600px",
                height: "500px",
                maxWidth: "90%",
                overflowY: "scroll",
              }}
            >
              <div className="p-4 text-center sm:p-0">
                <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                  <div className="px-5 py-5">
                    <div className="flex justify-between items-center">
                      <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                        {Object.keys(selectedSite).length > 0
                          ? "Update Product"
                          : "Add New Product"}
                      </h2>
                      <button
                        className=""
                        onClick={() => {
                          setOpenForm(false);
                          setSiteData({});
                          setSelectedSite({});
                        }}
                        type="reset"
                      >
                        <FaTimes />
                      </button>
                    </div>
                    <form onSubmit={handleSubmit} className="addsite">
                      <h6 className="small">
                        Product Name <span style={{ color: "red" }}>*</span>{" "}
                      </h6>
                      <input
                        className="textbox"
                        type="text"
                        name="name"
                        placeholder="Enter Product Name"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={siteData.name}
                      />
                      {errors.name && (
                        <p className="error-text">{errors.name}</p>
                      )}
                      <h6 className="small">
                        Product Code <span style={{ color: "red" }}>*</span>{" "}
                      </h6>
                      <input
                        className="textbox"
                        type="text"
                        name="item_code"
                        placeholder="Enter Product Code"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={siteData.item_code}
                      />
                      {errors.item_code && (
                        <p className="error-text">{errors.item_code}</p>
                      )}
                      {/* <h6 className="small">Select Category</h6>
                      <select
                        className="textbox"
                        placeholder="Please Select Category"
                        name="stock_category_id"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={siteData?.stock_category_id}
                      >
                        {errors.stock_category_id && (
                          <p className="error-text">
                            {errors.stock_category_id}
                          </p>
                        )}
                        {categoryOptions ? (
                          Object.values(categoryOptions)?.map((item, index) => (
                            <option value={item?.id}>{item?.name}</option>
                          ))
                        ) : (
                          <span>N/A</span>
                        )}
                      </select> */}

                      <h6 className="small">
                        Select Unit <span style={{ color: "red" }}>*</span>{" "}
                      </h6>
                      <select
                        className="textbox"
                        placeholder="Please Select Unit"
                        name="stock_unit_id"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={siteData?.stock_unit_id}
                      >
                        {errors.stock_unit_id && (
                          <p className="error-text">{errors.stock_unit_id}</p>
                        )}
                        {unitOptions ? (
                          Object.values(unitOptions)?.map((item, index) => (
                            <option value={item?.id}>{item?.name}</option>
                          ))
                        ) : (
                          <span>N/A</span>
                        )}
                      </select>
                      <h6 className="small">
                        Product Price <span style={{ color: "red" }}>*</span>{" "}
                      </h6>
                      <input
                        className="textbox"
                        type="number"
                        name="price_per_unit"
                        placeholder="Enter Product Price"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        min="0.00"
                        step="0.1"
                        presicion={2}
                        value={siteData.price_per_unit}
                      />
                      {errors.price_per_unit && (
                        <p className="error-text">{errors.price_per_unit}</p>
                      )}
                      <h6 className="small">
                        Product Weight 
                      </h6>
                      <input
                        className="textbox"
                        type="number"
                        name="weight_per_unit"
                        placeholder="Enter Product Weight"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        min="0.00"
                        step="0.1"
                        presicion={2}
                        value={siteData.weight_per_unit}
                      />
                      {errors.weight_per_unit && (
                        <p className="error-text">{errors.weight_per_unit}</p>
                      )}

                      <div className="flex w-100 gap-5 justify-space-space px-10 mt-4">
                        <button
                          className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#383838]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                          type="submit"
                        >
                          {Object.keys(selectedSite).length > 0 ? (
                            <span>Update</span>
                          ) : (
                            <span>Submit</span>
                          )}
                        </button>
                        <button
                          onClick={() => {
                            setOpenForm(false);
                            setSiteData({});
                            setSelectedSite({});
                          }}
                          type="reset"
                          className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                        >
                          Close
                        </button>
                      </div>
                      <ToastContainer position="top-center" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {addConfirmationModal && (
        <>
          <div className="fixed inset-0 flex items-top  justify-center z-50">
            <div
              className="absolute inset-0 backdrop-filter backdrop-blur-sm"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            ></div>
            <div className="relative top-20 z-50" style={{ maxWidth: "100%" }}>
              <div className="p-4 text-center sm:p-0 res">
                <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                  <div className="px-5 py-5 res">
                    <div className="flex" style={{ justifyContent: "end" }}>
                      <button
                        className=""
                        onClick={() => {
                          setAddConfirmationModal(false);
                        }}
                        type="reset"
                      >
                        <FaTimes />
                      </button>
                    </div>
                    <div>
                      <div className="flex py-10 justify-between items-center res">
                        {/* <p className="text-[16px] font-bold helvetica not-italic mb-4 text-red-600">
                          Are you sure you want to delete?
                        </p> */}
                        <div className="flex gap-10 pr-2">
                          <button
                            className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#383838]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                            onClick={async () => {
                              setOpenForm(true);
                              setSiteData({
                                stock_unit_id: unitOptions[0]?.id,
                              });
                              setAddConfirmationModal(false);
                            }}
                          >



                            Add Single Product
                          </button>
                          <button
                            onClick={() => {
                              setShowUploadFileForm(true);
                              setAddConfirmationModal(false);
                            }}
                            className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                          >
                            Upload Products Excel Shett
                          </button>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showUploadFileForm && (
        <>
          <div className="fixed inset-0 flex items-top  justify-center z-50">
            <div
              className="absolute inset-0 backdrop-filter backdrop-blur-sm"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            ></div>
            <div
              className="relative top-20 z-50"
              style={{ width: "600px", maxWidth: "90%" }}
            >
              <div className="p-4 text-center sm:p-0">
                <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                  <div className="px-5 py-5">
                    <div className="flex justify-between items-center">
                      <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                        Upload Excel File
                      </h2>
                      <button
                        className="ml-8"
                        onClick={() => {
                          setShowUploadFileForm(false)
                        }}
                        type="reset"
                      >
                        <FaTimes />
                      </button>
                    </div>
                    <form onSubmit={uploadExcel} className="addsite">
                      <h6 className="small">Select File</h6>
                      <input
                        className="textbox"
                        type="file"
                        name="file"
                        placeholder="Select File"
                        accept=".xlsx"
                        onChange={fileSelectedHandler}
                      />
                      {errors.file && (
                        <p className="error-text">{errors.file}</p>
                      )}

                      <div className="flex w-100 gap-10 justify-between px-10 mt-4">
                        <button
                          className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#383838]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                          type="submit"
                        >
                          Submit
                        </button>
                        <button
                          onClick={() => {
                            setShowUploadFileForm(false);
                          }}
                          type="reset"
                          className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" />
        </>
      )}
    </>
  );
};

export default ProductList;
