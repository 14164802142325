import React, { useEffect, useState, useMemo } from "react";
import "../../src/Table.css";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../services/axios";
import Pagination from "../components/PaginationComponent";
import "../pages/Addsite.css";
import { ToastContainer, toast } from "react-toast";
import { useParams } from "react-router-dom";
import { CheckPermission } from "../components/CheckPermissions";
import img1 from "../assests/empty1.png";
import NoData from "../components/NoData";
import { FaTimes } from "react-icons/fa";
import Loader from "../components/Loader";
let PageSize = 10;

const File = () => {
  const navigate = useNavigate();

  const [sitesList, setSitesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [folderDetails, setFolderDetails] = useState();
  const [categoryData, setCategoryData] = useState({
    name: "",
  });
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState();
  const state = { selectedFile: null, loaded: 0 };

  // const handleChang = (e) => {
  //   const { name, value } = e.target;

  // Validate the input here

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (categoryData.name == null || categoryData.name == "") {
      setErrors({ ...errors, [name]: "" });
    }

    setCategoryData({ ...categoryData, [name]: value });
    // setErrors(newErrors);
  };

  const fileSelectedHandler = (e) => {
    console.log(e);
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
    // state = {
    //   selectedFile: e.target.files[0],
    //   loaded: 0,
    // }
  };

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    fetchAllSites();
  }, [currentPage]);

  useEffect(() => {
    fetchAllSites();
    fetchFolderDetails();
  }, []);

  const fetchAllSites = async () => {
    setSitesList([]);
    setLoader(true);
    try {
      const response = await axiosInstance.get(
        `/files/getAllFiles?limit=${PageSize}&pageNo=${currentPage}&folder_id=${id}`
      );
      if (response.data["success"] == true) {
        console.log(response.data.data.files); // handle the response from the server
        setSitesList(response.data.data.files);
        setTotalCount(response.data.data.count);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const fetchFolderDetails = async () => {
    setLoader(true);
    try {
      const response = await axiosInstance.get(
        `/files/getAllFiles?limit=${PageSize}&pageNo=${currentPage}&folder_id=${id}`
      );
      if (response.data["success"] == true) {
        console.log(response.data.data.folders); // handle the response from the server
        setFolderDetails(response.data.data.folders[0]);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  useEffect(() => {
    console.log("Site Data : ", JSON.stringify(sitesList));
  }, [sitesList]);

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const deleteSelectedSite = async () => {
    console.log("Selected Site : ", selectedCategory);
    setLoader(true);
    try {
      const response = await axiosInstance.delete(
        `/files/deleteFile/${selectedCategory.id}`
      );
      if (response.data["success"] == true) {
        console.log(response); // handle the response from the server
        fetchAllSites();
        // setCurrentPage(0);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let apiUrl = null;
    let isError = false;
    setLoader(true);
    if (Object.keys(categoryData).length > 0) {
      Object.keys(categoryData).map((item, index) => {
        if (item != "created_at" && item != "updated_at" && item != "name") {
          if (categoryData?.[item] == null || categoryData?.[item] == "") {
            const { name, value } = {
              name: item,
              value: `${item} is required!`,
            };
            setErrors({ ...errors, [name]: value });
            isError = true;
          }
        }
      });
    }
    if (isError) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      return;
    }
    let fileName = file?.name.split(".");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", fileName[0]);
    formData.append("folder_id", id);
    // formData.append('site_id', categoryData?.name);
    // console.log("File : ", file);
    apiUrl = "/files/uploadFile";

    const headers = {
      "content-type": "multipart/form-data",
    };

    try {
      const response = await axiosInstance.post(apiUrl, formData, {
        headers: headers,
      });
      if (response.data["success"] == true) {
        fetchAllSites();
        // setCurrentPage(0);
        setSelectedCategory({});
        setCategoryData({});
        setOpenForm(false);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      if (error?.response?.status == 412) {
        setErrors(error?.response?.data?.data);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const downloadFile = async (item) => {
    setLoader(true);
    console.log("Selected Site : ", item);
    const FileSaver = require("file-saver");
    try {
      const response = await axiosInstance.get(
        `/files/downloadFile/${item.id}`
      );
      if (response["status"] == 200) {
        let file_url = response.data.data.file_path;
        let url = `https://api-sample1-ims.techdarshak.com/${file_url}`;
        FileSaver.saveAs(url, item?.file_path);

        // let a = document.createElement("a");
        // a.href = url;
        // a.download = item?.file_path;
        // a.click();
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 412) {
        setErrors(error?.response?.data?.data);
      }
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  return (
    <>
      <h1 className="heading"></h1>
      <div
        className="relative overflow-x-auto sm:rounded-lg"
        style={{ width: "100%", padding: "20px" }}
      >
        {loader ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className="bg-white tableDiv">
              <div className="">
                <CheckPermission permission="ADD_FILE">
                  <button
                    onClick={() => {
                      setOpenForm(true);
                      setSelectedCategory({});
                      setCategoryData({});
                    }}
                    className="btn-sm float-right"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      alignItems: "center",
                      width: "100px",
                      background: "#814f68",
                    }}
                  >
                    <span style={{ fontSize: "24px" }}>+</span> Add
                    {/* Add New Category */}
                  </button>
                </CheckPermission>
              </div>

              <div className="ml-4">
                <h1 className="text-2xl py-6">File</h1>
              </div>
              {sitesList.length > 0 ? (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  bg-white">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        {/* <input type="checkbox"></input> */}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        File name
                      </th>
                      <CheckPermission permission="SHOW_FILE_ACTION">
                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </CheckPermission>
                    </tr>
                  </thead>
                  <tbody>
                    {sitesList.length > 0 ? (
                      Object.values(sitesList)?.map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        >
                          <td scope="col" className="px-6 py-3">
                            {/* <input type="checkbox"></input> */}
                            {index + 1}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.name}
                          </td>
                          <CheckPermission permission="SHOW_FILE_ACTION">
                            <td className="px-6 py-4">
                              <div className="row align-item-center justify-space-space">
                                <a
                                  href="#"
                                  onClick={() => {
                                    setSelectedCategory(item);
                                    downloadFile(item);
                                  }}
                                  className="font-medium text-[#814f68]-600 dark:text-blue-500 hover:underline ml-4 pointer"
                                >
                                  Download
                                </a>

                                <a
                                  href="#"
                                  className="font-medium text-red-600 dark:text-red-500 hover:underline ml-4 pointer"
                                  onClick={() => {
                                    setSubmissionModal(true);
                                    setSelectedCategory(item);
                                  }}
                                >
                                  Delete
                                </a>
                              </div>
                            </td>
                          </CheckPermission>
                        </tr>
                      ))
                    ) : (
                      <NoData />
                    )}
                  </tbody>
                </table>
              ) : (
                <NoData />
              )}
            </div>
            {sitesList.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  className="mb-2"
                  totalPosts={totalCount}
                  postsPerPage={PageSize}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        {submissionModal && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-20 z-50"
                style={{ width: "800px", maxWidth: "100%" }}
              >
                <div className="p-4 text-center sm:p-0 res">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5 res">
                      <div className="flex justify-between items-center res">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          Please Confirm
                        </h2>
                        {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                      </div>
                      <div>
                        <div className="flex py-10 justify-between items-center res">
                          <p className="text-[16px] font-bold helvetica not-italic mb-4 text-red-700">
                            Are you sure you want to delete?
                          </p>
                          <div className="flex gap-10 pr-4">
                            <button
                              className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                              onClick={async () => {
                                setSubmissionModal(false);
                                // nextStep();
                                await deleteSelectedSite(true);
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => {
                                setSubmissionModal(false);
                              }}
                              className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {openForm && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-20 z-50"
                style={{ width: "600px", maxWidth: "90%" }}
              >
                <div className="p-4 text-center sm:p-0">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5">
                      <div className="flex justify-between items-center">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          {Object.keys(selectedCategory).length > 0
                            ? "Update File"
                            : "Add New File"}
                        </h2>
                        <button
                          className="ml-8"
                          onClick={() => {
                            setOpenForm(false);
                            setCategoryData({});
                          }}
                          type="reset"
                        >
                          <FaTimes />
                        </button>
                      </div>
                      <form onSubmit={handleSubmit} className="addsite">
                        {/* <h6 className="small">Category Name</h6>
                        <input
                          className="textbox"
                          type="text"
                          name="name"
                          placeholder="Enter Category Name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={categoryData.name}
                        />
                        {errors.name && (
                          <p className="error-text">{errors.name}</p>
                        )} */}
                        <h6 className="small">Select File</h6>
                        <input
                          className="textbox"
                          type="file"
                          name="file"
                          placeholder="Select File"
                          onChange={fileSelectedHandler}
                        />
                        {errors.file && (
                          <p className="error-text">{errors.file}</p>
                        )}
                        <div className="flex w-100 gap-5 justify-space-space px-10 mt-4">
                          <button
                            className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                            type="submit"
                          >
                            {Object.keys(selectedCategory).length > 0 ? (
                              <span>Update</span>
                            ) : (
                              <span>Submit</span>
                            )}
                          </button>
                          <button
                            onClick={() => {
                              setOpenForm(false);
                            }}
                            type="reset"
                            className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                          >
                            Close
                          </button>
                        </div>
                        <ToastContainer position="top-center" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default File;
