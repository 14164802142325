import React from "react";
import "./pagination.css"
import "./FooterStyles.css"
const Footer = () => {
  return (
    <footer className="footer">
      <div style={{ textAlign:'center' }} >
        <a class="text1">© 2023 Copyright:</a>
        <a
          class="text2 "
          href="https://techdarshak.com/"
          target="_blank"
        >
          {" "}
          Designed and Developed By TechDarshak
        </a>
      </div>
    </footer>
  );
};

export default Footer;
