import React, { useEffect, useState, useMemo } from "react";
import "../../src/Table.css";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../services/axios";
import Pagination from "../components/PaginationComponent";
import commonService from "../services/CommonServices";
import "../pages/Addsite.css";
import { FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toast";
import { CheckPermission } from "../components/CheckPermissions";
import img1 from "../assests/empty1.png";
import NoData from "../components/NoData";

import Loader from "../components/Loader";
let PageSize = 10;

const SiteList = () => {
  const navigate = useNavigate();

  const [sitesList, setSitesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [submissionModal, setSubmissionModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [siteData, setSiteData] = useState({
    name: "",
    address: "",
    start_date: null,
    end_date: null,
    remark: "",
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSiteData({ ...siteData, [name]: value });
  };

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    fetchAllSites();
  }, [currentPage]);

  useEffect(() => {
    fetchAllSites();
  }, []);

  useEffect(() => {
    console.log("selected site : ", siteData);
  }, siteData);

  const fetchAllSites = async () => {
    setSitesList([]);
    setLoader(true);
    try {
      const response = await axiosInstance.get(
        `/sites/getAllSites?limit=${PageSize}&pageNo=${currentPage}`
      );
      if (response.data["success"] == true) {
        console.log(response.data.data.sites); // handle the response from the server
        setSitesList(response.data.data.sites);
        setTotalCount(response.data.data.count);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  useEffect(() => {}, [sitesList]);

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  const deleteSelectedSite = async () => {
    setLoader(true);
    try {
      const response = await axiosInstance.delete(
        `/sites/deleteSite/${selectedSite.id}`
      );
      if (response.data["success"] == true) {
        console.log(response); // handle the response from the server
        fetchAllSites();
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      toast.error(error?.response?.data?.message);
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  const handleSubmit = async (e) => {
    setErrors({});
    setLoader(true);
    e.preventDefault();
    let apiUrl = null;
    let isError = null;
    if (Object.keys(siteData).length > 0) {
      Object.keys(siteData).map((item, index) => {
        if (
          item != "created_at" &&
          item != "updated_at" &&
          item != "end_date" && item != "deleted_at" &&
          item != "remark"
        ) {
          if (siteData?.[item] == null || siteData?.[item] == "") {
            console.log("106  :", item);
            const { name, value } = {
              name: item,
              value: `${item} is required!`,
            };
            isError = true;
            setErrors({ ...errors, [name]: value });
          }
        }
      });
    } else {
      setErrors({
        name: "Name is required",
        address: "Address is required",
        start_date: "Start Date is required",
      });
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      return;
    }
    if (isError) {
      setLoader(false);
      return;
    }
    if (Object.keys(errors)?.length > 0) {
      Object.keys(errors).map((item, index) => {
        if (errors?.[item] != null || errors?.[item] != "") {
          const { name, value } = {
            name: item,
            value: `${item} is required!`,
          };
          setErrors({ ...errors, [name]: value });
          setTimeout(() => {
            setLoader(false);
          }, 1000);
          return;
        }
      });
    }
    if (Object.keys(selectedSite).length > 0) {
      apiUrl = `/sites/updateSites/${selectedSite?.id}`;
    } else {
      apiUrl = "/sites/addSites";
    }
    try {
      const response = await axiosInstance.post(apiUrl, siteData);
      if (response.data["success"] == true) {
        fetchAllSites();
        setSelectedSite({});
        setSiteData({});
        setOpenForm(false);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      console.log(error); // handle the error
      if (error?.response?.status == 412) {
        setErrors(error?.response?.data?.data);
      }
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        localStorage.clear();
        navigate("/login");
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  };

  return (
    <>
      <h1 className="heading"></h1>
      <div
        className="relative overflow-x-auto sm:rounded-lg"
        style={{ width: "100%", padding: "20px" }}
      >
        {" "}
        {loader ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className="bg-white tableDiv">
              <div className="">
                <CheckPermission permission="ADD_SITE">
                  <button
                    onClick={() => {
                      setOpenForm(true);
                      setSiteData({});
                      setSelectedSite({});
                      setErrors({});
                    }}
                    className="btn-sm float-right Abtn"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100px",
                      background: "#814f68",
                    }}
                  >
                    <span style={{ fontSize: "24px" }}>+</span> Add
                    {/* Add New Site */}
                  </button>
                </CheckPermission>
              </div>
              <div className="ml-4">
                <h1 className=" text-2xl py-6 ">Sites</h1>
              </div>
              {sitesList.length > 0 ? (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  bg-white">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        {/* <input type="checkbox"></input> */}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Sites name
                      </th>
                      <th scope="col" className="px-6 py-3 width">
                        Sites Address
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Start Date
                      </th>
                      <th scope="col" className="px-6 py-3">
                        End Date
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Remark
                      </th>
                      <CheckPermission permission="SHOW_SITE_ACTION">
                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </CheckPermission>
                    </tr>
                  </thead>
                  <tbody>
                    {sitesList.length > 0 ? (
                      Object.values(sitesList)?.map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        >
                          <td scope="col" className="px-6 py-3">
                            {/* <input type="checkbox"></input> */}
                            {index + 1}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.name}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <span style={{ wordWrap: "break-word" }}>
                              {item.address}
                            </span>
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.start_date}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.end_date ? item.end_date : "N/A"}
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.remark ? item.remark : "N/A"}
                          </td>
                          <CheckPermission permission="SHOW_SITE_ACTION">
                            <td className="px-6 py-4">
                              <div className="row align-item-center justify-space-space">
                                <a
                                  href="#"
                                  onClick={() => {
                                    setSiteData(item);
                                    setOpenForm(true);
                                    setSelectedSite(item);
                                    setErrors({});
                                  }}
                                  className="font-medium text-[#814f68]-600 dark:text-blue-500 hover:underline ml-4"
                                >
                                  Edit
                                </a>

                                <a
                                  href="#"
                                  className="font-medium text-red-600 dark:text-blue-500 hover:underline ml-4"
                                  onClick={() => {
                                    setSubmissionModal(true);
                                    setSelectedSite(item);
                                  }}
                                >
                                  Delete
                                </a>
                              </div>
                            </td>
                          </CheckPermission>
                        </tr>
                      ))
                    ) : (
                      <NoData />
                    )}
                  </tbody>
                </table>
              ) : (
                <NoData />
              )}
            </div>
            {sitesList.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  className="mb-2"
                  totalPosts={totalCount}
                  postsPerPage={PageSize}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        {submissionModal && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50">
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-20 z-50"
                style={{ width: "800px", maxWidth: "100%" }}
              >
                <div className="p-4 text-center sm:p-0 res">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5 res">
                      <div className="flex justify-center items-center">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          Please Confirm
                        </h2>
                        {/* <GrFormClose
                          onClick={() => setSubmissionModal(false)}
                        /> */}
                      </div>
                      <div>
                        <div className="flex py-10 justify-between items-center res">
                          <p className="text-[16px] font-bold helvetica not-italic mb-4 text-red-600">
                            Are you sure you want to delete?
                          </p>
                          <div className="flex gap-10 pr-2">
                            <button
                              className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#00617F]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                              onClick={async () => {
                                setSubmissionModal(false);
                                // nextStep();
                                await deleteSelectedSite(true);
                              }}
                            >
                              Yes
                            </button>
                            <button
                              onClick={() => {
                                setSubmissionModal(false);
                              }}
                              className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838F] px-10 py-2 rounded-[8px] border border-[#814f68]"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {openForm && (
          <>
            <div className="fixed inset-0 flex items-top  justify-center z-50" style={{ overflow:'auto' }} >
              <div
                className="absolute inset-0 backdrop-filter backdrop-blur-sm"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              ></div>
              <div
                className="relative top-15 z-50"
                style={{ width: "600px", maxWidth: "90%" }}
              >
                <div className="p-4 text-center sm:p-0">
                  <div className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl">
                    <div className="px-5 py-5">
                      <div className="flex justify-between items-center">
                        <h2 className="text-black text-[20px] font-bold helvetica not-italic">
                          {Object.keys(selectedSite).length > 0
                            ? "Update Site"
                            : "Add New Site"}
                        </h2>
                        <button
                          className=""
                          onClick={() => {
                            setOpenForm(false);
                            setSiteData({});
                          }}
                          type="reset"
                        >
                          <FaTimes />
                        </button>
                      </div>
                      <form className="addsite" onSubmit={handleSubmit}>
                        <h6 className="small">
                          Site Name <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <input
                          className="textbox"
                          type="text"
                          name="name"
                          placeholder="Enter Site Name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={siteData["name"]}
                        />
                        {errors.name && (
                          <p className="error-text">{errors.name}</p>
                        )}
                        <h6 className="small">
                          Address <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <textarea
                          className="textbox"
                          rows={3}
                          name="address"
                          placeholder="Enter Address"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            height: "70px",
                          }}
                          value={siteData?.address}
                        />
                        {errors.address && (
                          <p className="error-text">{errors.address}</p>
                        )}
                        <h6 className="small">
                          Start Date <span style={{ color: "red" }}>*</span>{" "}
                        </h6>
                        <input
                          className="textbox"
                          type="date"
                          name="start_date"
                          placeholder="Select Start Date "
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={siteData?.start_date}
                        />
                        {errors.start_date && (
                          <p className="error-text">{errors.start_date}</p>
                        )}
                        <h6 className="small">End Date </h6>
                        <input
                          className="textbox"
                          type="date"
                          name="end_date"
                          placeholder="Select End Date"
                          min={siteData?.start_date}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={siteData.end_date}
                        />
                        {errors.end_date && (
                          <p className="error-text">{errors?.end_date}</p>
                        )}
                        <h6 className="small">Site Remark </h6>
                        <textarea
                          className="textbox"
                          rows={3}
                          name="remark"
                          placeholder="Enter Remark"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            height: "70px",
                          }}
                          value={siteData?.remark}
                        />
                        {errors.remark && (
                          <p className="error-text">{errors.remark}</p>
                        )}
                        <div className="flex w-100 gap-5 justify-space-space px-10 mt-4">
                          <button
                            className="bg-[#814f68] text-[16px] font-bold helvetica border hover:border-[#814f68] hover:bg-[#fff] hover:text-[#383838]  text-[#FFFFFF] px-10 py-2 rounded-[8px]"
                            type="submit"
                          >
                            {Object.keys(selectedSite).length > 0 ? (
                              <span>Update</span>
                            ) : (
                              <span>Submit</span>
                            )}
                          </button>
                          <button
                            onClick={() => {
                              setOpenForm(false);
                              setSiteData({});
                            }}
                            type="reset"
                            className="bg-[#FFFFFF] text-[16px] font-bold helvetica hover:bg-[#814f68] hover:text-[#fff] text-[#383838] px-10 py-2 rounded-[8px] border border-[#814f68]"
                          >
                            Close
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer position="top-center" />
    </>
  );
};

export default SiteList;
